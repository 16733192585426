.games-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #8c6262;
}
.games-features {
  width: 100%;
  height: 592px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.games-text {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.games-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.games-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 16px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card:hover {
  transform: scale(1.02);
}
.games-text01 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card1:hover {
  transform: scale(1.02);
}
.games-text02 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image1 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 16px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card2:hover {
  transform: scale(1.02);
}
.games-text03 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image2 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card3:hover {
  transform: scale(1.02);
}
.games-text04 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image3 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card4:hover {
  transform: scale(1.02);
}
.games-text05 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image4 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card5 {
  width: 100%;
  height: 196px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card5:hover {
  transform: scale(1.02);
}
.games-text06 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image5 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card6 {
  width: 100%;
  height: 196px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card6:hover {
  transform: scale(1.02);
}
.games-text07 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image6 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-feature-card7 {
  width: 100%;
  height: 196px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
}
.games-feature-card7:hover {
  transform: scale(1.02);
}
.games-text08 {
  margin-bottom: var(--dl-space-space-twounits);
}
.games-image7 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.games-navlink {
  display: contents;
}
.games-text09 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .games-text {
    align-self: center;
  }
  .games-container1 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .games-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .games-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .games-container1 {
    grid-gap: var(--dl-space-space-halfunit);
  }
}
