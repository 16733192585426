.old-blogs-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.old-blogs-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-blog-post-card:hover {
  transform: scale(1.02);
}
.old-blogs-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.old-blogs-container01 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.old-blogs-container02 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-text {
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-text01 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-profile {
  display: flex;
  align-items: center;
}
.old-blogs-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.old-blogs-text02 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.old-blogs-blog-post-card1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-blog-post-card1:hover {
  transform: scale(1.02);
}
.old-blogs-image2 {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.old-blogs-container04 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.old-blogs-container05 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-text03 {
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-text04 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-container06 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-profile1 {
  display: flex;
  align-items: center;
}
.old-blogs-image3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.old-blogs-text09 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.old-blogs-blog-post-card2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-blog-post-card2:hover {
  transform: scale(1.02);
}
.old-blogs-image4 {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.old-blogs-container07 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.old-blogs-container08 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.old-blogs-text12 {
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-text13 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  align-self: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.old-blogs-container10 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.old-blogs-profile2 {
  display: flex;
  align-items: center;
}
.old-blogs-image5 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.old-blogs-text14 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.old-blogs-text15 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}
.old-blogs-navlink {
  display: contents;
}
.old-blogs-text16 {
  align-self: flex-start;
  text-decoration: none;
}
@media(max-width: 991px) {
  .old-blogs-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
  .old-blogs-blog-post-card1 {
    flex-direction: row;
    justify-content: space-between;
  }
  .old-blogs-blog-post-card2 {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .old-blogs-blog-post-card {
    flex-direction: column;
  }
  .old-blogs-image {
    width: 100%;
  }
  .old-blogs-blog-post-card1 {
    flex-direction: column;
  }
  .old-blogs-image2 {
    width: 100%;
  }
  .old-blogs-blog-post-card2 {
    flex-direction: column;
  }
  .old-blogs-image4 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .old-blogs-container03 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .old-blogs-container06 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .old-blogs-container10 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .old-blogs-text15 {
    width: auto;
  }
}
