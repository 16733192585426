.partners-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.partners-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.partners-container1 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.partners-blog-post-card {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partners-blog-post-card:hover {
  transform: scale(1.02);
}
.partners-container2 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.partners-blog-post-card1 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partners-blog-post-card1:hover {
  transform: scale(1.02);
}
.partners-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.partners-container3 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text {
  color: var(--dl-color-gray-500);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.partners-link {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.partners-text1 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
  margin-bottom: var(--dl-space-space-twounits);
}
.partners-container4 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.partners-profile {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.partners-text2 {
  font-weight: 300;
  margin-left: var(--dl-space-space-halfunit);
}
.partners-container5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partners-blog-post-card2 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partners-blog-post-card2:hover {
  transform: scale(1.02);
}
@media(max-width: 991px) {
  .partners-blog {
    flex-direction: column;
  }
  .partners-container1 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .partners-container2 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .partners-image {
    height: 350px;
  }
}
@media(max-width: 767px) {
  .partners-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .partners-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
