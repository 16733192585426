.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.blog-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.blog-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-link {
  display: contents;
}
.blog-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
  text-decoration: none;
}
.blog-link1 {
  display: contents;
}
.blog-container1 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  text-decoration: none;
}
.blog-container2 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-text {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text1 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-profile {
  display: flex;
  align-items: center;
}
.blog-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-text2 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-navlink {
  display: contents;
}
.blog-text3 {
  align-self: flex-end;
  text-decoration: none;
}
.blog-navlink1 {
  display: contents;
}
.blog-text4 {
  align-self: flex-start;
  text-decoration: none;
}
@media(max-width: 991px) {
  .blog-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .blog-blog-post-card {
    flex-direction: column;
  }
  .blog-image {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-container3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
