.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: #d9eb09;
}
.home-branding {
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-text {
  color: rgb(39, 39, 45);
  font-size: 30px;
  font-family: Inter;
}
.home-desktop-menu {
  display: flex;
}
.home-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-right {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-link {
  text-decoration: none;
}
.home-link01 {
  text-decoration: none;
}
.home-link02 {
  text-decoration: none;
}
.home-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-work-with-us {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #ffffff;
}
.home-work-with-us:hover {
  opacity: 0.5;
}
.home-text01 {
  display: flex;
  font-size: 24px;
}
.home-image {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-sidebar {
  width: 300px;
  height: 170px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-nav1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-work-with-us1 {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(229, 254, 88);
}
.home-work-with-us1:hover {
  opacity: 0.5;
}
.home-text02 {
  display: flex;
  font-size: 24px;
}
.home-image01 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding1 {
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  border-color: #61616B;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-text03 {
  color: #27272D;
  font-size: 30px;
  font-family: Inter;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-middle {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-work-with-us2 {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgb(229, 254, 88);
}
.home-work-with-us2:hover {
  opacity: 0.5;
}
.home-text04 {
  display: flex;
  font-size: 24px;
}
.home-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
  background-color: #D9EB09;
}
.home-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text05 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 54px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 400;
}
.home-hero-image {
  width: 100%;
  height: 635px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #ffffff;
}
.home-image02 {
  width: 282px;
  height: 556px;
  object-fit: cover;
  background-size: cover;
  background-image: url("https://play.teleporthq.io/static/svg/default-img.svg");
}
.home-who {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
  background-color: #D9EB09;
}
.home-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.home-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-text06 {
  width: 100%;
  max-width: 800px;
}
.home-text07 {
  color: rgb(39, 39, 45);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.home-navlink {
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-700);
}
.home-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.home-video {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-video1 {
  width: 100%;
  cursor: pointer;
  margin-top: 77px;
}
.home-information {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 120px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: 170px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 140px;
  background-color: #D9EB09;
  border-top-width: 1px;
}
.home-purpose {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-caption {
  color: #27272D;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-description {
  width: 100%;
  font-size: 30px;
  max-width: 900px;
  line-height: 54px;
}
.home-text08 {
  color: #27272d;
}
.home-text09 {
  color: #0000ff;
}
.home-book {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #D9EB09;
}
.home-content {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 810px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text11 {
  font-size: 54px;
  font-weight: initial;
  line-height: 65px;
}
.home-link06 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: lowercase;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}
.home-image03 {
  height: 400px;
  object-fit: cover;
}
.home-footer {
  width: 100%;
  height: 428px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-primary-500);
}
.home-header2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-branding2 {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.home-content1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #61616B;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-contact {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-email {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.home-links-row {
  gap: 128px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-links-column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-link07 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link07:hover {
  opacity: 0.7;
}
.home-link08 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link08:hover {
  opacity: 0.7;
}
.home-link09 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link09:hover {
  opacity: 0.7;
}
.home-link10 {
  color: rgb(39, 39, 45);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link10:hover {
  opacity: 0.7;
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text15 {
  color: #f30000;
}
.home-link11 {
  display: contents;
}
.home-icon2 {
  width: auto;
  height: 24px;
  align-self: center;
  text-decoration: none;
}
.home-socials {
  gap: var(--dl-space-space-twounits);
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text01 {
    line-height: 30px;
  }
  .home-text02 {
    line-height: 30px;
  }
  .home-text04 {
    line-height: 30px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text05 {
    font-size: 32px;
  }
  .home-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 750px;
    padding-bottom: 0px;
  }
  .home-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    gap: var(--dl-space-space-unit);
  }
  .home-text07 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-navlink {
    font-size: 16px;
  }
  .home-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-video {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .home-information {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
    border-top-width: 0px;
  }
  .home-purpose {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption {
    font-size: 12px;
  }
  .home-description {
    font-size: 16px;
    line-height: 28px;
  }
  .home-book {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .home-left {
    gap: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .home-text11 {
    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .home-text14 {
    font-size: 16px;
  }
  .home-image03 {
    height: 220px;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header2 {
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-branding2 {
    font-size: 18px;
  }
  .home-content1 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-email {
    font-size: 24px;
  }
  .home-links-row {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-socials {
    width: 100%;
    display: flex;
    padding-top: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-row {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-row1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-links {
    display: none;
  }
  .home-work-with-us {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text01 {
    display: none;
  }
  .home-image {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .home-sidebar {
    height: 70vh;
  }
  .home-burger-menu {
    display: flex;
    align-self: flex-start;
  }
  .home-work-with-us1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text02 {
    display: none;
  }
  .home-image01 {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .home-branding1 {
    max-width: 80%;
  }
  .home-work-with-us2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-who {
    margin-bottom: 0px;
  }
  .home-video {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
  .home-video1 {
    margin-top: 0px;
  }
}
@media(max-width: 479px) {
  .home-mobile-menu {
    padding: 16px;
  }
  .home-video {
    bottom: -419px;
  }
}
