.coming-soon-games-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #ffff01;
}
.coming-soon-games-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.coming-soon-games-text {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.coming-soon-games-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.coming-soon-games-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.coming-soon-games-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.coming-soon-games-text1 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.coming-soon-games-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.coming-soon-games-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.coming-soon-games-text2 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.coming-soon-games-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.coming-soon-games-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.coming-soon-games-text3 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.coming-soon-games-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.coming-soon-games-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.coming-soon-games-text4 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 767px) {
  .coming-soon-games-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .coming-soon-games-container1 {
    grid-template-columns: 1fr;
  }
  .coming-soon-games-feature-card {
    flex-direction: row;
  }
  .coming-soon-games-container2 {
    flex-direction: column;
  }
  .coming-soon-games-feature-card1 {
    flex-direction: row;
  }
  .coming-soon-games-container3 {
    flex-direction: column;
  }
  .coming-soon-games-feature-card2 {
    flex-direction: row;
  }
  .coming-soon-games-container4 {
    flex-direction: column;
  }
  .coming-soon-games-feature-card3 {
    flex-direction: row;
  }
  .coming-soon-games-container5 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .coming-soon-games-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .coming-soon-games-container2 {
    flex-direction: column;
  }
  .coming-soon-games-container3 {
    flex-direction: column;
  }
  .coming-soon-games-container4 {
    flex-direction: column;
  }
  .coming-soon-games-container5 {
    flex-direction: column;
  }
}
